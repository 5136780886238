<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    src="@/assets/login.jpg"
    mobile-break-point="960"
    app
    width="270"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <base-item
        :item="clientItem"
        class="mb-3"
      />

      <base-item
        :item="baseItem"
        class="mb-3"
      />

<!--      <template v-for="(item, i) in computedItems">-->
      <draggable
        handle=".catalog-drag"
        :scroll-sensitivity="250"
        :fallback-tolerance="1"
        :force-fallback="true"
        v-model="computedItems"
        @end="updateCatalogsOrder"
      >
        <transition-group>
        <base-item-group
          v-for="item in computedItems"
          :key="item.id"
          :item="item"
        >
          <!--  -->
        </base-item-group>
        </transition-group>
      </draggable>
<!--      </template>-->

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template class="ma-3" v-slot:append>
      <div class="pb-3 pr-4 pl-4">
        <v-text-field placeholder="Додати список" v-model="catalog.title" :error-messages="responseErrors.title">
          <v-btn icon slot="append" small @click="storeCatalog">
            <v-icon color="blue">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-text-field>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import catalog from "../../../../services/catalog";
  import draggable from "vuedraggable";

  // Utilities
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    components: {draggable},

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      catalog: {
        title: ''
      },
      responseErrors: {
        title: []
      },

      baseItem: {
        icon: 'mdi mdi-home-search',
        title: 'Фільтр',
        to: '/',
        isBase: true
      },

      clientItem: {
        icon: 'mdi mdi-account-group',
        title: 'Клієнти',
        to: '/clients',
        isBase: true,
        isClients: true
      },

      items: [],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },

      computedItems: {
        get(){
          return this.items.map(this.mapItem)
        },
        set(newComputedItems){
          return this.items = newComputedItems
        }
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    mounted() {
      this.getCatalogs()
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.categories ? (item.categories.length !== 0 ? item.categories.map(this.mapItem) : undefined) : undefined,
          title: this.$t(item.title),
        }
      },

      getCatalogs() {
        catalog.get()
          .then((res) => {if (res) this.items = res.data.data;})
      },

      storeCatalog() {
        catalog.store(this.catalog)
          .then((res) => { if (res) this.$router.go(0); })
          .catch((err) => {if (err.status === 422) this.responseErrors = err.data.errors;})
      },

      updateCatalogsOrder(e) {
        catalog.updateOrder(this.computedItems)
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
